
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { defineComponent, computed, onMounted, ref } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import moment from "moment";
import { tsXLXS } from "ts-xlsx-export";

export default defineComponent({
  components: {
    Datatable,
  },
  setup() {
    const tableHeader = ref([
      {
        name: "PROMOTION NAME",
        key: "promotion_name",
        sortable: false,
      },
      {
        name: "DISCOUNT",
        key: "discount",
        sortable: false,
      },
      {
        name: "AMOUNT",
        key: "amount",
        sortingField: "status.label",
        sortable: false,
      },
      {
        name: "USE / CANCEL",
        key: "used_cancel",
        sortingField: "status.label",
        sortable: false,
      },
      {
        name: "VALUE PRICE(THB)",
        key: "total_value_price",
        sortingField: "status.label",
        sortable: false,
      },
      {
        name: "PAID PRICE(THB)",
        key: "total_paid_price",
        sortingField: "status.label",
        sortable: false,
      },
      {
        name: "",
        key: "",
        sortingField: "status.label",
        sortable: false,
      },
    ]);
    const store = useStore();

    const reportList = computed(() => {
      return store.getters.promotionReport;
    });

    const originalConfig = computed(() => {
      return store.state.ReportModule.originalPromotionConfig;
    });

    onMounted(() => {
      setCurrentPageTitle("Promotion Report");
    });

    function exportReportToExcel() {
      let data: Record<string, string>[] = [];
      for (let val of reportList.value) {
        const form = {
          "Promotion name": val.promotion_name,
          Discount: val.discount,
          amount: val.amount,
          "Use/Cancel": val.used_cancel,
          "Total value price (THB)": val.total_value_price,
          "Total paid price (THB)": val.total_paid_price,
        };
        data.push(form);
      }

      const sumForm = {
        "Promotion name": "Total",
        Discount: "",
        amount: "",
        "Use/Cancel": "",
        "Total value price (THB)": reportList.value
          .reduce(
            (partial_sum, a) => partial_sum + parseFloat(a.total_value_price),
            0
          )
          .toString(),
        "Total paid price (THB)": reportList.value
          .reduce(
            (partial_sum, a) => partial_sum + parseFloat(a.total_paid_price),
            0
          )
          .toString(),
      };

      data.push(sumForm);

      tsXLXS().exportAsExcelFile(data)
        .saveAsExcelFile(`Promotion Report  ${moment(
        originalConfig.value.startDate
      ).format("YYYY/MM/DD")} -
                ${moment(originalConfig.value.endDate).format("YYYY/MM/DD")}`);
    }
    return {
      moment,
      originalConfig,
      tableHeader,
      reportList,
      exportReportToExcel,
    };
  },
});
