import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7729cd3d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "row",
  style: {"border-radius":"25px","top":"50px"}
}
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-body-custom" }
const _hoisted_4 = {
  key: 0,
  style: {"text-align":"right"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.originalConfig != null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("div", {
                    class: "btn btn-info",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.exportReportToExcel()))
                  }, " Download excel ")
                ]))
              : _createCommentVNode("", true),
            (_ctx.originalConfig != null)
              ? (_openBlock(), _createBlock(_component_Datatable, {
                  key: 1,
                  "table-header": _ctx.tableHeader,
                  "table-data": _ctx.reportList,
                  "rows-per-page": 5,
                  "enable-items-per-page-dropdown": true
                }, {
                  "cell-promotion_name": _withCtx(({ row: item }) => [
                    _createElementVNode("span", null, _toDisplayString(item.promotion_name), 1)
                  ]),
                  "cell-discount": _withCtx(({ row: item }) => [
                    _createElementVNode("span", null, _toDisplayString(item.discount), 1)
                  ]),
                  "cell-amount": _withCtx(({ row: item }) => [
                    _createElementVNode("span", null, _toDisplayString(item.amount), 1)
                  ]),
                  "cell-used_cancel": _withCtx(({ row: item }) => [
                    _createElementVNode("span", null, _toDisplayString(item.used_cancel), 1)
                  ]),
                  "cell-total_value_price": _withCtx(({ row: item }) => [
                    _createElementVNode("span", null, _toDisplayString(item.total_value_price), 1)
                  ]),
                  "cell-total_paid_price": _withCtx(({ row: item }) => [
                    _createElementVNode("span", null, _toDisplayString(item.total_paid_price), 1)
                  ]),
                  _: 1
                }, 8, ["table-header", "table-data"]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ]))
}